import {Navbar, NavbarBrand} from 'reactstrap'
import logo from '../logo.svg'

function MainMenu() {

    return(
        <>
            <Navbar
                // className="my-2"
                color="dark"
                dark
                full
                container
                expand
                // fixed='top'
            >
                <NavbarBrand href="/">
                    <img
                    alt="logo"
                    src={logo}
                    style={{
                        height: 40,
                        width: 40
                    }}
                    />
                     HK.00700 量化分析指标
                </NavbarBrand>
            </Navbar>
        </>
    )
    
}

export default MainMenu;