import '../assets/App.css';
import { Container } from 'reactstrap';
import MainMenu from '../components/nav';
import YYDSChart from '../components/yyds';
import Footer from '../components/footer';
function App() {

  return (
    <>
      <MainMenu/>
      <Container className='section_light'>
        <YYDSChart/>
      </Container>
      <Footer/>
    </>
  );
}

export default App;
