import { useState, useEffect } from 'react';
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Scatter,
    ResponsiveContainer,
  } from 'recharts';


function YYDSChart() {

    const [data, setData] = useState([])

    useEffect(() => {
      const interval = setInterval(() => {
        fetchData();
      }, 5000);
      
      return () => clearInterval(interval);
  
    },[])
  
    const fetchData = () => {
      const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
      }
      fetch('https://yyds.hk00700.com/api/yyds', requestOptions)
      .then(response => {
        if (response.ok) return response.json();
        throw response;
      })
      .then(json => {
        console.log(json);
  
        var array = json;
        for (let index = 0; index < array.length; index++) {
          const element = array[index];
  
          if (index > 5)
          {
            if (element['d'] > 10)
            {
              if (
                // 0 < array[index-2]['b'] &&
                array[index-2]['b'] < array[index-1]['b'] &&
                array[index-1]['b'] < array[index]['b'] &&
                array[index]['d'] > 0
              )
              {
                element['red'] = element['e']
              }
              
            }
          }
  
          if (element['b'] >=0 )
          {
            element['b1'] = 0
          }
          else
          {
            element['b1'] = element['b']
            element['b'] = 0
          }
  
        }
        var c = 330 - array.length;
        for (let index = 0; index < c; index++) {
          array.push({})
        }
        
        console.log(array)
        setData(array);
      })
      .catch(err => {
        console.error(err);
      });
    }

    
    return(
        <>
    <ResponsiveContainer width="100%" height="60%">
      <ComposedChart
        syncId="anyId"
        data={data}
      >
      {/* <CartesianGrid stroke="#f5f5f5" /> */}
      <Line type="monotone" dataKey="f" stroke="#8884d8" dot={false} />
      {/* <Scatter name="buy" dataKey="red" fill="red" /> */}
      <XAxis />
      <YAxis domain={['auto','auto']}/>
      <Tooltip content={<></>}/>
      {/* <Legend /> */}
    </ComposedChart>
  </ResponsiveContainer>
  <ResponsiveContainer width="100%" height="40%">
      <ComposedChart
        syncId="anyId"
        data={data}
      >
      {/* <CartesianGrid stroke="#f5f5f5" /> */}
      {/* <Line type="monotone" dataKey="e" stroke="#8884d8" dot={false} /> */}
      <Area type="monotone" dataKey="a" fill="#8884d8" stroke="#8884d8"/>
      <Bar dataKey="b" barSize={40} fill="#ff0000"/>
      <Bar dataKey="b1" barSize={40} fill="#00ff00"/>
      <Line type="monotone" dataKey="c" stroke="#f00000" dot={false}/>
      <Line type="monotone" dataKey="d" stroke="#8884d8" dot={false}/>
      <Line type="monotone" dataKey="e" stroke="#8884d8" dot={false}/>

      <XAxis />
      <YAxis domain={[-100,100]}/>
      <Tooltip content={<></>}/>
      <Legend />
    </ComposedChart>
  </ResponsiveContainer>
        </>
    )
    
}

export default YYDSChart;