import { Container } from "reactstrap";
import '../assets/App.css';

function Footer() {
    return(
        <>
            <Container className="footer">
                Email:robber5#live.com
            </Container>
        </>
    )
    
}

export default Footer;